import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import authLoginReducer from 'src/auth/login/auth.slice';
import loginReducer from 'src/auth/login/login.slice';
import adminReducer from 'src/admin/admin.slice';
import storeAdminReducer from 'src/store-admin/storeAdmin.slice';
import qrCodeReducer from 'src/qr-code/qrCode.slice';
import requestReducer from 'src/request-manage/request-list/list-slice';
import requestDetailReducer from 'src/request-manage/request-detail/slice';

// ----------------------------------------------------------------------
const rootPersistConfig = {
  key: 'vitadairy',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['authLogin', 'login'],
};

const rootReducer = combineReducers({
  authLogin: authLoginReducer,
  login: loginReducer,
  storeAdmin: storeAdminReducer,
  admin: adminReducer,
  qrCode: qrCodeReducer,
  request: requestReducer,
  requestDetail: requestDetailReducer,
});

export { rootPersistConfig, rootReducer };
