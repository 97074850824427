import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "src/common/redux/store";
import { ISliceDetail } from "./interface";

const initialState: ISliceDetail = {
    isPopup: false,
    rejectDes: ''
  };
  
  export const requestDetailSlice = createSlice({
    name: 'detailRequest',
    initialState,
    reducers: {
      setPopup: (state, action: PayloadAction<boolean>) => {
        state.isPopup = action.payload;
      },
      setRejectDes: (state, action: PayloadAction<string>) => {
        state.rejectDes = action.payload;
      }
    },
  });
  
  export const { setPopup, setRejectDes } =
  requestDetailSlice.actions;

  export const isPopup = (state: RootState) => state.requestDetail.isPopup
  export const dataRejectDes = (state: RootState) => state.requestDetail.rejectDes
  
  
  export default requestDetailSlice.reducer;
  