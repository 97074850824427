import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'src/common/redux/store';
import { defaultValueFile, defaultValueSearch } from './list-constants';
import { ISliceForm, ISearchRequest, IFormRequest } from './list-interface';

const initialState: ISliceForm = {
    searchForm: defaultValueSearch,
    isExport: false,
    id: 0,
    isLoading: false,
    fileName: '',
    paramAppove:{
      page:0,
    limit: 0,
    searchText: '',
    startDate: '',
    endDate: ''
    }
  };
  
  export const requestListSlice = createSlice({
    name: 'listRequest',
    initialState,
    reducers: {
      setSearchForm: (state, action: PayloadAction<ISearchRequest>) => {
        state.searchForm = action.payload;
      },
      setExportPopup: (state, action: PayloadAction<boolean>) => {
        state.isExport = action.payload;
      },
      setFileId: (state, action: PayloadAction<number>) => {
        state.id = action.payload;
      },
      setLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
      },
      setFileName: (state, action: PayloadAction<string>) => {
        state.fileName = action.payload;
      },
      setParamAppove: (state, action: PayloadAction<ISliceForm['paramAppove']>) => {
        state.paramAppove = action.payload;
      },
    },
  });
  
  export const { setSearchForm, setExportPopup, setFileId, setParamAppove,setLoading, setFileName } =
  requestListSlice.actions;
  export const dataSearch = (state: RootState) => state.request.searchForm;
  export const isExportPopup = (state: RootState) => state.request.isExport;
  export const idFile = (state: RootState) => state.request.id;
  export const loading = (state: RootState) => state.request.isLoading;
  export const fileName = (state: RootState) => state.request.fileName;
  export const paramAppove = (state: RootState) => state.request.paramAppove;
  
  
  
  export default requestListSlice.reducer;
  