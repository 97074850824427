import { IFormRequest } from "./list-interface";

export const HEAD_TABLE_PROPS = [
  {
    id: 'file',
    label: 'Tên file',
    align: 'left',
  },
  {
    id: 'browseDate',
    label: 'Ngày duyệt',
    align: 'left',
  },
  {
    id: 'sku',
    label: 'Loại sản phẩm',
    align: 'left',
  },
  {
    id: 'weight',
    label: 'Khối lượng',
    align: 'left',
  },
  {
    id: 'quantity',
    label: 'Số lượng mã',
    align: 'left',
  },
  {
    id: 'factoryName',
    label: 'Nhà máy',
    align: 'left',
  },
  {
    label: 'Xuất file',
    align: 'left',
  },
  {
    label: '',
    align: 'left',
  },
];

export const defaultValueSearch = {
    searchText: '',
    startDate:  null,
    endDate: null,
}

export const defaultValueFile: IFormRequest = {
  id: 0,
  name: '',
  approvedDate: '',
  codeQuantity: 0,
  status: '',
  codeQuantityCreated: 0,
  rejectDescription: null,
  factory: '',
  type: '',
  description: '',
  sku: '',
  weight: 0,
  manufactureDate: '',
  isAcitve: false,
  accRequestId: 0,
  nameUserRequest: '',
  accApproveId: 0,
  createdAt: '',
  updatedAt: '',
  deletedAt:  null,
  version: 0,
  }